<template>
    <div class="login">
        <div class="site-content">
            <div class="login-main">
                <div class="card bind-mail" v-if="bindMail">
                    <section-title><div class="card-title">绑定邮箱</div></section-title>
                    <div class="card-body">
                        <div class="bindForm">
                            <div class="form-item">
                                <label for="mail">邮箱</label>
                                <input class="v" type="email" id="mail" v-model="msgData.mail">
                            </div>
                            <div class="form-item">
                                <label for="mailVerify">验证码</label>
                                <div class="form-item-inline">
                                    <input class="v" id="mailVerify" v-model="msgData.code"/>
                                    <button @click="getMailCode" class="code-btn" :class="{'disabled':canGetCode}" :disabled="canGetCode">{{timer?`${during}s后重发`:'获取验证码'}}</button>
                                </div>
                            </div>
                            <div class="form-item">
                                <label></label>
                                <button @click="handleSubmit" :class="{'disabled':disabled}" :disabled="disabled">绑定</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card auth" v-else>
                    <section-title><div class="card-title">快速登录</div></section-title>
                    <div class="card-body">
                        <div class="auth">
                            <div class="auth-title">第三方应用授权</div>
                            <div class="auth-types">
                                <div v-for="item in authType" :key="item.type" :title="item.title" @click="authHandle(item.type)" :style="{'background-color': item.bgColor}">
                                    <i class="iconfont" :class="item.icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import sectionTitle from '@/components/section-title'
    import { sendMailCode, thirdAuth } from '../api'
    import { checkMail } from '@/utils'
    import {mapGetters} from 'vuex'
    export default {
        name: "login",
        data(){
          return{
              bindMail: false,
              userInfo: '',
              msgData: {
                  mail: '',
                  code: ''
              },
              during: 90,
              timer: undefined
          }
        },
        components: {sectionTitle},
        computed: {
            ...mapGetters([
                'websiteInfo'
            ]),
            queries() {
                return this.$route.query
            },
            disabled() {
                return !this.msgData.code || !checkMail(this.msgData.mail)
            },
            canGetCode() {
                return this.timer || !checkMail(this.msgData.mail)
            },
            authType() {
                let a = []
                for (let key in this.websiteInfo) {
                    if (key.indexOf("thirdlogin_") !== -1) {
                        a.push(this.websiteInfo[key])
                    }
                }
                return a
            }
        },
        watch: {
            queries: {
                immediate: true,
                deep: true,
                handler(v){
                    if (v.code) {
                        this.authCallback()
                    }
                }
            }
        },
        methods:{
            authHandle(type) {
                thirdAuth(type).then(res => {
                    window.localStorage.setItem("authType", type)
                    window.location.href = res.data
                }).catch(err => {
                    this.$message({
                        message: err,
                        type: 'error',
                        offset: 50
                    })
                })
            },
            authCallback(){
                let params = Object.assign({}, this.queries, {type: window.localStorage.getItem("authType")})
                this.$store.dispatch('loginAuth',params).then((data) => {
                    this.userInfo = data
                    this.$router.replace('/')
                }).catch(err => {
                    this.$message({
                        message: err,
                        type: 'error',
                        offset: 50
                    })
                })
            },
            resetForm(){
                this.msgData = {
                    mail: '',
                    code: ''
                }
                this.during = 90
                this.timer = undefined
            },
            handleSubmit(){
                const data = Object.assign({},this.userInfo,this.msgData)
            },
            getMailCode(){
                if (this.timer) {
                    return
                }
                sendMailCode(this.msgData.mail).then(() => {
                    this.timer = setInterval(() => {
                        if (this.during > 0) {
                            this.during--
                        }else {
                            clearInterval(this.timer)
                            this.timer = undefined
                            this.during = 90
                        }
                    },1000)
                    this.$message({
                        message: `前往${this.msgData.linkMail}查看验证码吧！`,
                        type: 'info',
                        offset: 50
                    })
                }).catch((err) => {
                    this.$message({
                        message: err,
                        type: 'error',
                        offset: 50
                    })
                })
            }
        },
        beforeDestroy() {
            this.resetForm()
        }
    }
</script>

<style scoped lang="less">
    .login{
        .login-main{
            padding-top: 200px;
        }
    }
    .card{
        padding: 20px;
        min-height: 100px;
        background-color: #FFFFFF;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .card-body{
            margin-top: 20px;
            .auth{
                height: 80px;
                .auth-types{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    & > div{
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        text-align: center;
                        line-height: 40px;
                        color: #FFFFFF;
                        cursor: pointer;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                    }
                }
                .auth-title{
                    padding: 0 20px 0;
                    margin: 30px 0;
                    line-height: 1px;
                    border-left: 100px solid #ddd;
                    border-right: 100px solid #ddd;
                    text-align: center;
                }
            }
            .bindForm{
                padding: 20px;
                .form-item{
                    align-items: center;
                    display: flex;
                    &:not(:last-child){
                        margin-bottom: 20px;
                    }
                    label{
                        width: 100px;
                    }
                    .v{
                        min-height: 40px;
                        line-height: 20px;
                        border-radius: 3px;
                        padding: 2px 10px;
                        outline:none;
                        border: 1px solid #8fd0cc;
                        width: 100%;
                        resize: vertical;
                    }
                    .form-item-inline{
                        width: 100%;
                    }
                    #mailVerify{
                        width: 50%;
                    }
                    button{
                        width: 100px;
                        height: 40px;
                        border-radius: 3px;
                        outline:0;
                        border-style: none;
                        cursor: pointer;
                        background-color: #409eff;
                        color: white;
                        &.code-btn{
                            width: 80px;
                            margin-left: 8%;
                        }
                        &:hover{
                            opacity: 0.8;
                        }
                        &.disabled{
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 800px) {
        .login{
            .login-main{
                padding-top: 70px;
            }
        }
    }
</style>
